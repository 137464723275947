<template>
  <section class="modal-card-body p-0">
      <iframe :src="info.url" :title="info.title" height="200" width="100%" allow="autoplay; fullscreen; xr-spatial-tracking"></iframe>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    //
  },
};
</script>

<style>
.moda-card-body {
  overflow: hidden;
}

iframe {
    height: -webkit-fill-available;
}
</style>